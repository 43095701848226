import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<LanguageSelectorComponent>,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    // this.translate.setTranslation
    // this.translate.use('fr');
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.bottomSheetRef.dismiss();
  }

}
