import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase} from '@angular/fire/database';
import { Router } from '@angular/router';

import { UpdateProfileComponent } from '../../settings/pages/update-profile/update-profile.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  userData:any;
  userDetails;


  constructor(
    private afAuth: AngularFireAuth, 
    private af: AngularFireDatabase,
    private router : Router
    
    ) {

      this.afAuth.authState.subscribe(user=>{
        if(user){

          this.userData = JSON.parse(JSON.stringify(user));
          localStorage.setItem('user',JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user'));
  
          user.getIdToken(true).then(function(idToken){
          localStorage.setItem('idToken',idToken);
  
  
          });
        }
  
        else{
  
          localStorage.setItem('user',null);
          JSON.parse(localStorage.getItem('user'));
          
        }
  
  
  
      })



    }

    getUserDetails(){

      this.afAuth.authState.subscribe(user=>{
        let uid = user.uid;
        if(uid){
          var x = this.af.object('users/'+uid);
          x.valueChanges().subscribe(item=>{


            localStorage.setItem('contrato_id',item['contrato_id']);
            localStorage.setItem('role',item['role']);
            localStorage.setItem('nome',item['nome']);

  
          })
        }
      });

  
    }

  /**
   * Function will authenticate user with email and password
   * @param {string} email
   * @param {string} password
   */
  public signIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then(async res=>{




      await this.getUserDetails();
    });

    
  }


  /**
   * Creates a new user email, full name and password
   * @param {string} email
   * @param {string} password
   * @param {string} fullName
   */
  public signUp(form) {
   

    return this.afAuth.createUserWithEmailAndPassword(form.value.email, form.value.password).then(res=>{

      let nome = form.value.nome;
      let role = form.value.role;
      let convenio = form.value.convenio;
      let key = res.user.uid;

      let detalheUser = {
        role : role,
        nome: nome,
        contrato_id: convenio

      }


      if(key){

        const itemRef = this.af.object(`/users/${key}`);

        itemRef.set(detalheUser);

      }


    });

  }



  /**
   * Function sends an email to provided mailId with a link to reset password
   * @param {string} email
   */
  public resetPassword(email: string) {
    console.log(email);
    return this.afAuth.sendPasswordResetEmail(email);
  }


  public signOut(){
    let removeStorage = localStorage.clear()

    if(removeStorage == null){
      this.afAuth.signOut();
    }
    
  }

   // Returns true when user is looged in and email is verified
   get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }



}
