import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { NgModule } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as firebase from 'firebase';
import * as fileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';


import {MatNativeDateModule, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { Observable } from 'rxjs';
import { error } from 'selenium-webdriver';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-pedidos-retorno-dialog',
  templateUrl: './pedidos-retorno-dialog.component.html',
  styleUrls: ['./pedidos-retorno-dialog.component.scss']
})

@NgModule({
  imports: [
    MatAutocompleteModule,
    MatButtonModule,
    CommonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatFormFieldModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  declarations: [PedidosRetornoDialogComponent]
})

export class PedidosRetornoDialogComponent implements OnInit {

  xmlFile;
  downloadIcon = true;

  constructor(
    private http: HttpClient,
    private alert: AlertService,
    private dialogRef: MatDialogRef<PedidosRetornoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) {



}


  ngOnInit() {
  }


  downloadXML(xml){

    this.xmlFile = xml;
    var storage = firebase.default.storage();
    var gsReference = storage.refFromURL('gs://agillemobile-c7082.appspot.com/xml_clientes/'+xml)

    let setHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
      'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',


    
    });

    gsReference.getDownloadURL().then(url =>{

      this.http.get(url,{headers:setHeaders,responseType: 'blob'} 
       ).subscribe(response => this.downLoadFile(response, "blob"));

        }).catch((err) => {

         this.alert.showError('Arquivo indisponível para download');

        });



  }


  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);

    fileSaver.saveAs(blob, this.xmlFile);
  
}


onPrint(divName) {
  const printContents = document.getElementById(divName).innerHTML;
  const originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
}






}

