// import { Directive } from '@angular/core';

// @Directive({
//   selector: '[appUserName]'
// })
// export class UserNameDirective {

//   constructor() { }

// }

import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';
import { UserService } from './../modules/settings/services/user.service';

@Directive({
  selector: '[appUserName]'
})
export class UserNameDirective implements OnInit, OnChanges {
  @Input('appUserName')
  userId: string;
  constructor(private el: ElementRef, private user: UserService) {
    // el.nativeElement.src = '/assets/spinner.gif';
  }

  ngOnInit() {
    this.updateName();
  }

  ngOnChanges() {
    this.updateName();
  }

  updateName() {
    if (!this.userId) {
      this.el.nativeElement.innerHTML = `Unnamed`;
      return false;
    }

    this.user.getUser(this.userId).subscribe(user => {
      if (!user) {
        this.el.nativeElement.innerHTML = `Unnamed`;
      } else if (user['fullName']) {
        this.el.nativeElement.innerHTML = `${user['fullName']}`;
      } else {
        this.el.nativeElement.innerHTML = `Unnamed`;
      }
    });
  }
}
