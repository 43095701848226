import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import {
  TranslateModule,
  TranslateLoader,
  TranslateCompiler,
  TranslateParser,
  MissingTranslationHandler,
  // CustomParser
} from '@ngx-translate/core';

import { UserAvatarDirective } from './../../directives/user-avatar.directive';
import { UserNameDirective } from './../../directives/user-name.directive';


import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { LoadingComponent } from './components/loading/loading.component';

// Pipes
import { FilterPipe } from './../../pipes/filter.pipe';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
//import { ChatComponent } from './components/chat/chat.component';
import { FooterComponent } from './components/footer/footer.component';
import { ChatConversationComponent } from './components/chat-conversation/chat-conversation.component';


const materialModules = [
  MatTabsModule
];


@NgModule({
  imports: [CommonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatListModule,
    MatToolbarModule,
    MatBottomSheetModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MomentModule,
    PickerModule,
    MatIconModule
  ],
  declarations: [
    HeaderComponent,
    UserAvatarDirective,
    UserNameDirective,
    LoadingComponent,
    FilterPipe,
    LanguageSelectorComponent,
    //ChatComponent,
    FooterComponent,
    ChatConversationComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    //ChatComponent,
    ChatConversationComponent,
    UserAvatarDirective,
    UserNameDirective,
    LoadingComponent,
    FilterPipe,
    MatProgressBarModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    PickerModule,
    ...materialModules
  ],
  entryComponents: [LanguageSelectorComponent,HeaderComponent]
})
export class SharedModule { }