import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import * as firebase from 'firebase/app';

// Device detector
import { DeviceDetectorService } from 'ngx-device-detector';

// Services
import { AlertService } from 'src/app/services/alert.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _user: any = new BehaviorSubject(null);
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private deviceService: DeviceDetectorService,
    private alert: AlertService
  ) {

    this.afAuth.user.subscribe(async user => {
      try {
        this.setUser(user);
        const token = await this.alert.getPermission();
        this.alert.getPushMessage();
        this.updatePushToken(token);
      } catch (error) {
      }

    });
  }

  public signOut() {
    this.afAuth.signOut();
  }

  private setUser(user) {
    this._user.next(user);
  }

  public get user(): BehaviorSubject<any> {
    return this._user;
  }


  /**
 * Update the User's push token
 * @param token string
 */
  public async updatePushToken(token: string) {

    const devices = await this.afs.firestore.collection('Devices').where('token', '==', token).get();
    if (devices.empty) {
      const deviceInfo = this.deviceService.getDeviceInfo();
      const data = {
        token: token,
        userId: this._user.uid,
        deviceType: 'web',
        deviceInfo: {
          browser: deviceInfo.browser,
          userAgent: deviceInfo.userAgent
        },
        createdAt: firebase.default.firestore.FieldValue.serverTimestamp()
      };
      await this.afs.firestore.collection('Devices').add(data);
      console.log('new Device Added');
    } else {
      console.log('Already existing Device');
    }
  }

  /**
   * Function will authenticate user with email and password
   * @param {string} email
   * @param {string} password
   */
  public signIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }



  /**
   * Creates a new user email, full name and password
   * @param {string} email
   * @param {string} password
   * @param {string} fullName
   */
  public signUp(email: string, password: string, fullName: string) {
    console.log(email, password, fullName);
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }


  /**
   * Function sends an email to provided mailId with a link to reset password
   * @param {string} email
   */
  public resetPassword(email: string) {
    console.log(email);
    return this.afAuth.sendPasswordResetEmail(email);
  }


}
