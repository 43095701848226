import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { AngularFireDatabase } from '@angular/fire/database';
import { map} from 'rxjs/operators';

@Component({
  selector: 'app-mundipagg-dialog-detalhes-component',
  templateUrl: './mundipagg-dialog-detalhes-component.component.html',
  styleUrls: ['./mundipagg-dialog-detalhes-component.component.scss']
})
export class MundipaggDialogDetalhesComponentComponent {

  sugestoesDanfe;
  isLoading = false;
  public Detform: FormGroup;
  

  constructor(
    private fb: FormBuilder,
    private af: AngularFireDatabase, 
    private dialogRef: MatDialogRef<MundipaggDialogDetalhesComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) {



}




ngOnInit() {
  
  this.Detform = this.fb.group({
    danfe: null,
    mundipagg_id :[this.data.detalhe.id],
    mundipagg_codigo:[this.data.detalhe.codigo],
    mundipagg_details:[this.data.detalhe.charges]

  
  });
}


  save() {
    this.dialogRef.close(this.Detform.value);
}

close() {
    this.dialogRef.close();
}


  carregarSugestoes(documento:null){

    this.isLoading = true;

    let sugestoes = this.af.list('/pedidos/', ref => ref.orderByChild('cpf_cnpj_cliente').equalTo(documento).limitToFirst(10));

   

  sugestoes.snapshotChanges().pipe(
    map(actions =>
      actions.map(a => ({key:a.key, values:a.payload.val()}))
      
      )
    ).subscribe(items=>{

      if(items){
        this.sugestoesDanfe = items;
        console.log(items)
    this.isLoading = false;

      }else{

        alert('sem sugestoes');
      }
      


    


    })



  }




  

}
