import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as firebase from 'firebase';



@Injectable({
  providedIn: 'root'
})
export class AlertService {
  messaging = firebase.default.messaging()
  constructor(private snackBar: MatSnackBar) {
    try {
      this.messaging = messaging();
    } catch (error) {
      this.showError(error.message);
    }
  }  

  showError(message) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000
    });
  }

  showSuccess(message) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000
    });
  }


  /**
 * Get Permission for Push and store
 * the user
 */
  getPermission() {
    return this.messaging.requestPermission().then(() => {
      return this.messaging.getToken();
    });
  }

  getPushMessage() {
    this.messaging.onMessage(payload => {
      console.log(payload);
      this.showSuccess(
        `${payload.notification.title} : ${payload.notification.body}`
      );
      // this.currentMessage.next(payload)
    });
  }
}
