import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-associa-motoboy-component',
  templateUrl: './associa-motoboy-component.component.html',
  styleUrls: ['./associa-motoboy-component.component.scss']
})
export class AssociaMotoboyComponentComponent implements OnInit {

  public Expform: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AssociaMotoboyComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) {



}

ngOnInit() {
  
  this.Expform = this.formBuilder.group({
    mensageiro:[this.data.mensageiro],

  });
}


  save() {
    this.dialogRef.close(this.Expform.value);
}

close() {
    this.dialogRef.close();
}

}
