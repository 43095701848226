// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: '2.0.2',
  firebase: {
    apiKey: "AIzaSyDPbGoa7Dn1vaGj7tJqzXn8IvIzO1838p0",
    authDomain: "agillemobile-c7082.firebaseapp.com",
    databaseURL: "https://agillemobile-c7082.firebaseio.com",
    projectId: "agillemobile-c7082",
    storageBucket: "agillemobile-c7082.appspot.com",
    messagingSenderId: "165623685732",
    appId: "1:165623685732:web:794bb11829a2f3d5"
  },


};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
