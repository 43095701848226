
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { HttpClientModule } from '@angular/common/http';


// Third Party Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { DatePipe } from '@angular/common';




// device detector
import { DeviceDetectorModule } from 'ngx-device-detector';

// Guards
import { AuthGuard } from './guards/auth.guard';

// Modules
import { SharedModule } from './modules/shared/shared.module';


// components
import { PedidosDialogComponentComponent } from './modules/pedidos-dialog-component/pedidos-dialog-component.component';
import { PedidosRetornoDialogComponent } from './modules/pedidos-retorno-dialog/pedidos-retorno-dialog.component';
import { AssociaMotoboyComponentComponent } from './modules/pedidos/associa-motoboy-component/associa-motoboy-component.component';

//material

import { MaterialModule } from './material/material.module';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule} from '@angular/material/menu';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import {MatGridListModule} from '@angular/material/grid-list';
import { MundipaggDialogDetalhesComponentComponent } from './modules/gerencial/mundipagg-dialog-detalhes-component/mundipagg-dialog-detalhes-component.component';

import { NgxCaptchaModule } from 'ngx-captcha';


// Routes Definition
const routes: Routes = [
  {
    path: 'auth',
    loadChildren:
      './modules/authentication/authentication.module#AuthenticationModule'
  },
  {
    path: 'settings',
    loadChildren: './modules/settings/settings.module#SettingsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'message',
    loadChildren: './modules/message/message.module#MessageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'chat',
    loadChildren: './modules/message/message.module#MessageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'starter',
    loadChildren: './modules/starter/starter.module#StarterModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'listapedidos',
    loadChildren: './modules/pedidos/listapedidos.module#ListapedidosModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'listapagamentos',
    loadChildren: './modules/gerencial/listapagamentos.module#ListapagamentosModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'mapa',
    loadChildren: './modules/mapa/mapa.module#MapaModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'rastreamento',
    loadChildren: './modules/rastreamento/rastreamento.module#RastreamentoModule',
  
  },

  {
    path: '**',
    redirectTo: 'listapedidos/pedidos'
  },

  
];

@NgModule({
  declarations: [AppComponent, PedidosDialogComponentComponent, PedidosRetornoDialogComponent,AssociaMotoboyComponentComponent,MundipaggDialogDetalhesComponentComponent],
  entryComponents: [PedidosDialogComponentComponent,PedidosRetornoDialogComponent,AssociaMotoboyComponentComponent,MundipaggDialogDetalhesComponentComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,

    DeviceDetectorModule.forRoot(),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    HttpClientModule,
    NgxCaptchaModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
   
  ],
  providers: [AuthGuard,DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
