import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(array: any, property?: any, term?: any): any {
    if (!term) {
      return array;
    }
    term = term.toLowerCase();
    return array.filter(item => {
      if (item[property]) {
        return item[property].toLowerCase().indexOf(term) !== -1;
      } else {
        return false;
      }
    });
  }
}
