import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';





import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public version = environment.version;
  constructor(private auth: AuthService,
    private afAuth: AngularFireAuth, 
    private af: AngularFireDatabase,
  ) {




    
  }

  role;
  username;
  isAdmin:boolean = false;




  ngOnInit() {


    this.afAuth.authState.subscribe(user=>{
      let uid = user.uid;
      if(uid){
        var x = this.af.object('users/'+uid);
        x.valueChanges().subscribe(item=>{


          localStorage.setItem('contrato_id',item['contrato_id']);
          this.username = item['nome'];


          if(item['role']=='admin'){

            this.isAdmin = true;
          }




        })
      }
    });

    


   



  }

  

  public signOut() {




    this.auth.signOut();
    window.location.href = '/auth/login';
    // signOut
  }




}
